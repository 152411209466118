<template>
    <div class="view-root flex-column">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <p class="common-title">会议房间列表</p>
            <p class="btn-wrap-width" @click="editRoom(null)">新增房间</p>
        </div>

        <div class="table-margin-top flex-1-overflow-y">
            <div class="table-header table-margin-bottom">
                <div class="table-index">序号</div>
                <div class="table-name">房间名称</div>
                <div class="table-location">房间位置</div>
                <div class="table-size">房间大小</div>
                <div class="table-arrange">房间排列</div>
                <div class="table-operation">操作</div>
            </div>

            <div v-for="(room, index) in dataList" :key="index" class="table-item table-margin-bottom">
                <div class="table-index">{{  (index+1)}}</div>
                <div class="table-name" style="font-weight: bold;">{{  room.name}}</div>
                <div class="table-location">{{  room.location}}</div>
                <div class="table-size">{{  room.room_size}}</div>
                <div class="table-arrange">{{ room.arrangement }}</div>
                <div class="table-operation" style="display: flex;">
                    <p class="list-btn-blue" style="text-align: left;padding-left: 8px;" @click="editRoom(room)">
                        编辑</p>
                    <p class="list-btn-red" style="margin-left: 10px;text-align: left;padding-left: 8px;"
                        @click="doRemoveRoom(room)">删除</p>
                </div>
            </div>
        </div>

        <AddMeetingRoom
            ref="addMeetingRoom"
            :meetingId="meeting_id"
            @on-complete="fetchData"
        ></AddMeetingRoom>
    </div>
</template>
<script>
import '../../assets/common/common.css'
import {
    getMeetingRooms,
    removeMeetingRoom,
    updateMeetingRoom,
} from '../../api/api'
import AddMeetingRoom from './addMeetingRoom.vue'

export default ({
    name: 'index',
    components: {
        AddMeetingRoom
    },

    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            dataList: [],
            roomInfo: {
                id: '',
                meeting_id: this.meeting_id,
                name: '',
                location: '',
                room_size: '',
                arrangement: ''
            },
            custom_arrangement: '',
            showRoomDialog: false
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            getMeetingRooms(this.meeting_id).then((res) => {
                this.dataList = res.data.data
            })
        },
        doRemoveRoom(row) {
            this.$alert('你确定要删除此会议房间吗？', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        removeMeetingRoom(row._id.$id).then((res) => {
                            this.fetchData()
                            this.$message.success('删除成功~')
                        })
                    }
                }
            })
        },
        editRoom(row) {
            this.$refs.addMeetingRoom.show(row)
        },
    }
})
</script>
<style scoped lang="scss">
.table-name {
    flex: 2;
}

.table-location {
    flex: 2;
}

.table-size {
    flex: 2;
}

.table-arrange {
    flex: 2;
}

.table-operation {
    flex: 1.5;
}
</style>